/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { usePathPrefix, useTc } from '../../../../../constants/hooks';

interface IProps {}

const CryptoRealizationsScreen: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const tc = useTc();

  return (
    <div className={styles.wrap}>
      <div className={styles.realizations}>
        <div className={styles.left_block}>
          <p className={styles.realizations_number}>
            {tc('cases.crypto.realizations.realizations_1.number')}
          </p>
          <h1 className={styles.title}>
            {tc('cases.crypto.realizations.realizations_1.title')}
          </h1>
          <p className={styles.desc}>
            {tc('cases.crypto.realizations.realizations_1.desc')}
          </p>
        </div>
        <div className={styles.img_realization}>
          <img
            src={`${prefix}/case/crypto/realization_1.png`}
            alt="realization_1"
          />
        </div>
      </div>
      <div className={styles.realizations}>
        <div className={styles.img_realization}>
          <img
            src={`${prefix}/case/crypto/realization_2.png`}
            alt="realization_2"
          />
        </div>
        <div className={styles.left_block}>
          <p className={styles.realizations_number}>
            {tc('cases.crypto.realizations.realizations_2.number')}
          </p>
          <h1 className={styles.title}>
            {tc('cases.crypto.realizations.realizations_2.title')}
          </h1>
          <p className={styles.desc}>
            {tc('cases.crypto.realizations.realizations_2.desc')}
          </p>
        </div>
      </div>
    </div>
  );
};

export { CryptoRealizationsScreen };

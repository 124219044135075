/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {
  usePathPrefix,
  useSSRSafeWindow,
  useTc,
} from '../../../../../constants/hooks';
import root from '../../styles/global.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';

interface IProps {}

const CryptoTitleScreen: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const tc = useTc();

  const win = useSSRSafeWindow();
  const onClick = () => {
    win?.scrollTo({ top: 601, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div id="title" className={classNames(root.screen, styles.wrap)}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs title={tc('cases.crypto.header.crypto')} />
        </div>

        <div className={styles.left_block}>
          <img
            src={`${prefix}/case/crypto/mobile_app.png`}
            className={styles.mobile_app_img}
            alt=""
          />
          <div className={styles.text}>
            <h1 className={styles.text__title}>
              {tc('cases.crypto.header.title')}
            </h1>
            <p className={styles.text__desc}>
              {tc('cases.crypto.header.desc')}
            </p>
          </div>
          <button className={styles.header_button} onClick={onClick}>
            {tc('cases.crypto.header.button')}
          </button>
        </div>
      </div>
      <div className={styles.right_block}>
        <img
          src={`${prefix}/case/crypto/background.png`}
          className={styles.background_img}
          alt=""
        />
        <img
          src={`${prefix}/case/crypto/app.png`}
          className={styles.app_img}
          alt=""
        />
      </div>
    </>
  );
};

export { CryptoTitleScreen };

import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { ICaseQuery } from '../../../../queries/cases/types';
import { CryptoTitleScreen } from '../../../../components/directions/crypto/screens/CryptoTitleScreen';
import { CryptoCardsScreen } from '../../../../components/directions/crypto/screens/CryptoCardsScreen';
import { CryptoStepsScreen } from '../../../../components/directions/crypto/screens/CryptoStepsScreen';
import { CryptoRealizationsScreen } from '../../../../components/directions/crypto/screens/CryptoRealizationsScreen';
import { CryptoFooterScreen } from '../../../../components/directions/crypto/screens/CryptoFooterScreen';
import root from '../../../../components/directions/crypto/styles/global.module.scss';

type IProps = PageProps<
  ICaseQuery,
  { locale: string; tag: string; page: number }
>;

const Crypto: FC<IProps> = ({
  pageContext: { locale = 'ru', tag = '', page = 0 },
}) => (
  <MainLayout locale={locale} title="menu.directions">
    <div className={root.case}>
      <CryptoTitleScreen />
      <CryptoCardsScreen />
      <CryptoStepsScreen />
      <CryptoRealizationsScreen />
      <CryptoFooterScreen />
    </div>
  </MainLayout>
);

export default Crypto;
